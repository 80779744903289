import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

Sentry.init({
    dsn: process.env.SENTRY_DSN,
    integrations: [
        new Integrations.BrowserTracing(),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
});

if(process.env.NODE_ENV === "production") {
    (function(s, w, r, e, p, o, t) {s[p]=s[p]||function() {
        (s[p].e = b[u].e || []).push(arguments)};
        o = w.createElement(r),t = w.getElementsByTagName(r)[0];
        o.async = 1;o.setAttribute('siteId','6ac1cc6a-284b-4a9c-aa47-004a6e235cc2');
        o.src = e;t.parentNode.insertBefore(o, t)
    })(window, document, 'script', 'https://script.sw.report/sw.js', 'swreport');
}
