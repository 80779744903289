import dayjs from 'dayjs';
import * as Sentry from "@sentry/browser";
import './monitoring';

const NOTE_TO_FREQUENCY = {
    '<': 77.7817,
    'E2': 82.4069,
    'F2': 87.3071,
    'F#2': 92.4986,
    'G2': 97.9989,
    'G#2': 103.826,
    'A2': 110.000,
    'A#2': 116.541,
    'B2': 123.471,
    'C3': 130.813,
    'C#3': 138.591,
    'D3': 146.832,
    'D#3': 155.563,
    'E3': 164.814,
    'F3': 174.614,
    'F#3': 184.997,
    'G3': 195.998,
    'G#3': 207.652,
    'A3': 220.000,
    'A#3': 233.082,
    'B3': 246.942,
    'C4': 261.626,
    'C#4': 277.183,
    'D4': 293.665,
    'D#4': 311.127,
    'E4': 329.628,
    'F4': 349.228,
    'F#4': 369.994,
    'G4': 391.995,
    'G#4': 415.305,
    'A4': 440.000,
    'A#4': 466.164,
    'B4': 493.883,
    'C5': 523.251,
    'C#5': 554.365,
    'D5': 587.330,
    'D#5': 622.254,
    'E5': 659.255,
    'F5': 698.456,
    'F#5': 739.989,
    'G5': 783.991,
    'G#5': 830.609,
    'A5': 880.000,
    'A#5': 932.328,
    'B5': 987.767,
    'C6': 1046.50,
    'C#6': 1108.73,
    'D6': 1174.66,
    '>': 1244.51,
};

const NOTES = [
    'C',
    'C#',
    'D',
    'D#',
    'E',
    'F',
    'F#',
    'G',
    'G#',
    'A',
    'A#',
    'B',
];

(async function () {
    const playedNoteElement = document.getElementById("played-note");
    const nextNoteElement = document.getElementById("next-note");
    const timerElement = document.getElementById("timer");
    const correctNotesCounterElement = document.getElementById("correct-notes-counter");
    const restartButton = document.getElementById("restart-button");
    const retryButton = document.getElementById("retry-button");
    const infoElement = document.getElementById("info");
    const callToActionElement = document.getElementById("call-to-action");

    let timerRunning = false;
    let correctNotesCounter = 0;
    let nextNote;
    let mediaStream;

    setup();

    async function setup() {
        const accepted = await getPermissions();
        if (accepted) {
            setupWebAudioAnalyser(playedNote => handlePlayedNote(playedNote));
            displayCorrectNotesCounter();
            start();
            Sentry.captureMessage("Training started");
            setupRestartButton();
        } else {
            setupRetryButton();
        }
    }

    function handlePlayedNote(playedNote) {
        playedNoteElement.innerText = playedNote;

        if (!timerRunning) {
            return;
        }

        if (playedNote === nextNote) {
            displayRandomNote();
            correctNotesCounter++;
            displayCorrectNotesCounter();
        }
    }

    async function getPermissions() {
        let accepted = true
        infoElement.innerText = "Waiting for microphone permissions...";
        try {
            mediaStream = await navigator.mediaDevices.getUserMedia({audio: true});
            infoElement.style.display = "none";
            retryButton.style.display = "none";
        } catch (e) {
            infoElement.innerText = "Please allow your browser to access your microphone to record yourself. Click retry or check the browser setting if this message remains.";
            console.error(e)
            accepted = false
        }
        return accepted
    }

    function setupWebAudioAnalyser(callback) {
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const analyser = audioContext.createAnalyser();
        const source = audioContext.createMediaStreamSource(mediaStream);
        source.connect(analyser);

        analyser.fftSize = 8192;
        const bufferLength = analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);

        const sampleRate = audioContext.sampleRate;
        const hertzPerBufferItem = sampleRate / 2 / bufferLength;

        (function detectEveryFrame() {
            window.requestAnimationFrame(detectEveryFrame);
            let loudest = 0;
            let loudestIndex = 0;
            analyser.getByteFrequencyData(dataArray);
            for (let i = 0; i < bufferLength; i++) {
                if (dataArray[i] > loudest) {
                    loudest = dataArray[i];
                    loudestIndex = i;
                }
            }
            const loudestFrequency = loudestIndex * hertzPerBufferItem;
            let loudestNote = getNoteForFrequency(loudestFrequency);

            if (loudest < 100 || loudestNote === "<" || loudestNote === ">") {
                loudestNote = "--";
            }
            callback(loudestNote);
        })();
    }

    function getNoteForFrequency(inputFrequency) {
        const sortedByClosestFrequency = Object.entries(NOTE_TO_FREQUENCY).sort(([aNote, aFrequency], [bNote, bFrequency]) => {
            return Math.abs(inputFrequency - aFrequency) - Math.abs(inputFrequency - bFrequency);
        })
        return sortedByClosestFrequency[0][0].replace(/\d/g, '');
    }

    function displayRandomNote() {
        const randomNoteIndex = Math.round(Math.random() * (NOTES.length - 1));
        const randomNote = NOTES[randomNoteIndex];
        nextNoteElement.innerText = randomNote;
        nextNote = randomNote
    }

    function displayCorrectNotesCounter() {
        correctNotesCounterElement.innerText = correctNotesCounter;
    }

    async function start() {
        timerElement.innerText = "01:00";
        await countdown()
        displayRandomNote();
        startTimer();
    }

    function startTimer() {
        timerRunning = true;
        const now = new Date();
        const deadline = new Date(now.getTime() + 1000 * 60);

        window.requestAnimationFrame(updateTimer);

        function updateTimer() {
            if (!timerRunning) {
                return;
            }
            window.requestAnimationFrame(updateTimer);
            const remaining = new Date(deadline - new Date());
            if (remaining.getTime() <= 0) {
                timerRunning = false;
                timerElement.innerText = "00:00";
                nextNoteElement.innerText = "--"
                Sentry.captureMessage("Training finished");
                animateCallToAction();
            } else {
                timerElement.innerText = dayjs(remaining).format("mm:ss");
            }
        }
    }

    async function countdown() {
        nextNoteElement.innerText = 3;
        await wait(1)
        nextNoteElement.innerText = 2;
        await wait(1)
        nextNoteElement.innerText = 1;
        await wait(1)
        nextNoteElement.innerText = "GO!";
        await wait(1)
    }

    function setupRestartButton() {
        restartButton.onclick = restart;
        restartButton.style.display = "block";
    }

    function setupRetryButton() {
        retryButton.onclick = retryPermissions;
        retryButton.style.display = "block";
    }

    function retryPermissions() {
        Sentry.captureMessage("Retried accepting permissions");
        setup();
    }

    function restart() {
        timerRunning = false;
        timerElement.innerText = "01:00";
        correctNotesCounter = 0;
        displayCorrectNotesCounter();
        start();
        Sentry.captureMessage("Training re-started");
    }

    function wait(seconds) {
        return new Promise(res => setTimeout(res, 1000 * seconds));
    }

    function animateCallToAction() {
        // will only work once
        callToActionElement.classList.add("animate__shakeX");
    }
})();
